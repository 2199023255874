import React from 'react';
import { useGetMeQuery } from '../../../graphql/_generated';
import { Avatar, AvatarSize } from '@alirosoftware/web-components';
import Link from 'next/link';
import classnames from 'classnames';
import { userSignupLoginRedirectLocation } from '../../../utils/user';

export type AvatarDropdownMenuProps = {
  hideOnMobile?: boolean;
  href?: string;
};

export const AvatarDropdownMenu = ({ hideOnMobile, href }: AvatarDropdownMenuProps) => {
  const { data } = useGetMeQuery({ fetchPolicy: 'cache-first' });

  const me = data?.me;
  if (!me) {
    return null;
  }

  return (
    <Link
      href={href || userSignupLoginRedirectLocation(me)}
      className={classnames({ 'hidden-sm-down': hideOnMobile })}
      data-testid="avatar-dropdown-menu"
    >
      <Avatar img={me.pictureUrl || undefined} size={AvatarSize.XSmall} />
    </Link>
  );
};
