import React, { useRef } from 'react';
import { Grid } from '../../core/layout/grid/grid';
import { Formik } from 'formik';
import { Button, ButtonColor, ButtonPull, ButtonSize } from '../../core/button/button';
import { DynamicDataTemplate, EditTemplateFormProps, LOCALE_LABELS, SmsTemplateContent } from '../types';
import { ConfirmationButton } from '../../core/button/confirmation-button';
import { useTranslation } from 'next-i18next';
import { DynamicDataPicker } from '../editor/dynamic-data-picker';
import { smsValidationSchema } from '../validations';
import { Box } from '../../core/layout/box/box';
import { Modal } from '../../core/modal/modal';
import { FormInput, FormInputRef } from '../../core/form/form-input';

export const EditSmsTemplateForm = ({
  initial,
  templateName,
  title,
  onTemplateUpdated,
  backendAdapter,
}: EditTemplateFormProps<SmsTemplateContent>) => {
  const bodyRef = useRef<FormInputRef>(null);
  const { t } = useTranslation();
  const { error, updateTemplate } = backendAdapter.useUpdateTemplate({ templateName, onTemplateUpdated });
  const { templates } = backendAdapter.useTemplateDynamicData({ templateName });
  const [lastActiveInput, setLastActiveInput] = React.useState<'body' | 'subject'>('body');
  const [dynamicDataOpen, setDynamicDataOpen] = React.useState(false);
  const onOpenDynamicData = () => setDynamicDataOpen(true);
  const onCloseDynamicData = () => setDynamicDataOpen(false);

  const onInsertDynamicDataTemplate = (dataTemplate: DynamicDataTemplate) => {
    bodyRef?.current?.insertContent(`{{ ${dataTemplate.key} }}`);
    onCloseDynamicData();
  };

  return (
    <>
      <Formik
        initialValues={{ locale: initial.locale, body: initial.body }}
        validationSchema={smsValidationSchema}
        validateOnChange={false}
        validateOnBlur={true}
        validateOnMount={false}
        isInitialValid={true}
        onSubmit={updateTemplate}
      >
        {({ handleSubmit, submitForm, isSubmitting, errors }) => {
          return (
            <Grid.Container fluid>
              <Grid.Row>
                <Grid.Column
                  colspan={12}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  alignContent="center"
                >
                  <h5 className="u-mb-0">
                    {title} ({LOCALE_LABELS[initial.locale]})
                  </h5>

                  <Box className="actions" display="flex" flexDirection="row" alignItems="center" alignContent="center">
                    <Button
                      size={ButtonSize.Small}
                      color={ButtonColor.Complete}
                      className="u-ml-2 u-mb-0"
                      onClick={onOpenDynamicData}
                      data-testid="insert-data"
                    >
                      {t('emailTemplates.insertDynamicData')}
                    </Button>
                  </Box>
                </Grid.Column>
              </Grid.Row>

              {error && (
                <Grid.Row className="error-messages">
                  <Grid.Column colspan={12}>
                    <p>{error}</p>
                  </Grid.Column>
                </Grid.Row>
              )}

              <Grid.Row>
                <Grid.Column colspan={12}>
                  <form onSubmit={handleSubmit}>
                    <FormInput
                      ref={bodyRef}
                      id="body"
                      name="body"
                      type="text"
                      className="u-full-width"
                      placeholder="Body"
                      onFocus={() => setLastActiveInput('body')}
                    />

                    <ConfirmationButton
                      type="button"
                      pull={ButtonPull.Right}
                      color={ButtonColor.Primary}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={submitForm}
                      className="u-mt-2"
                      confirmation={{
                        title: t('emailTemplates.save.confirmTitle'),
                        message: t('emailTemplates.save.confirmMessage'),
                        confirm: t('emailTemplates.save.confirmButton'),
                        cancel: t('emailTemplates.save.cancelButton'),
                      }}
                    >
                      {t('emailTemplates.save.confirmTitle')}
                    </ConfirmationButton>
                  </form>
                </Grid.Column>
              </Grid.Row>
            </Grid.Container>
          );
        }}
      </Formik>

      {dynamicDataOpen ? (
        <Modal open={true} onClose={onCloseDynamicData}>
          <DynamicDataPicker
            templates={lastActiveInput === 'body' ? templates : templates.filter((t) => t.type === 'value')}
            onTemplatePicked={onInsertDynamicDataTemplate}
          />
        </Modal>
      ) : null}
    </>
  );
};
