import { GenericTemplatesBackendAdapter, TemplateContent, TemplateType } from './types';
import { CreateTemplateButton } from './create/create-template-button';
import { TemplateList } from './template-list';
import { ButtonColor, ButtonPull } from '../core/button/button';
import { ButtonSize } from '../core/button/button';
import { useTranslation } from 'next-i18next';

export type FollowupTemplateListProps<Content extends TemplateContent> = {
  onClickTemplate: (template: Content) => void;
  backendAdapter: GenericTemplatesBackendAdapter<Content>;
  templateType: TemplateType;
  templateName: string;
};

export const FollowupTemplateList = <Content extends TemplateContent>({
  onClickTemplate,
  templateType,
  templateName,
  backendAdapter,
}: FollowupTemplateListProps<Content>) => {
  const { t } = useTranslation();
  const { templates, reload } = backendAdapter.useTemplates({ templateName });
  const { deleteTemplate } = backendAdapter.useDeleteTemplate({
    templateName,
    onTemplateDeleted: reload,
  });

  if (!templates) {
    return null;
  }

  return (
    <>
      <TemplateList templates={templates} onEditTemplate={onClickTemplate} onDeleteTemplate={deleteTemplate} />
      <CreateTemplateButton
        size={ButtonSize.Small}
        fullWidth={true}
        color={ButtonColor.Primary}
        pull={ButtonPull.Right}
        templateType={templateType}
        templateName={templateName}
        templates={templates}
        onTemplateUpdated={(template) => {
          reload().then(() => {
            onClickTemplate(template);
          });
        }}
        backendAdapter={backendAdapter}
        className="u-mt-2"
      >
        {t('emailTemplates.addNewLocale')}
      </CreateTemplateButton>
    </>
  );
};
