import React from 'react';
import { Button, ButtonVariant } from '@alirosoftware/web-components';
import { useLogout } from '../../../utils/user';
import { useTranslation } from 'next-i18next';

export const LogoutLink = () => {
  const { t } = useTranslation();
  const { onClickLogout } = useLogout();

  return (
    <Button variant={ButtonVariant.Text} onClick={onClickLogout} className="m-0 p-0 mr-4 u-color-black" testId="logout">
      {t('logOut')}
    </Button>
  );
};
