import React from 'react';
import { FormInput, FormInputProps } from './form-input';
import { Icon, IconSize } from '../icon/icon';
import { BsEye } from 'react-icons/bs';
import * as Yup from 'yup';
import { useTranslation } from 'next-i18next';

export type FormPasswordInputProps = FormInputProps & {
  showText?: string;
  hideText?: string;
};

type FormPasswordValidationSchemaParams = {
  minLength?: number;
  lowercase?: boolean;
  number?: boolean;
  special?: boolean;
  uppercase?: boolean;
  t: (key: string) => string;
};
export const formPasswordValidationSchema = ({
  minLength = 8,
  lowercase,
  uppercase,
  number,
  special,
  t,
}: FormPasswordValidationSchemaParams) => {
  let schema = Yup.string();

  const errorMessage = t('passwordValidations.all').replace('{min}', minLength?.toString());

  if (minLength) {
    schema = schema.min(minLength, errorMessage);
  }

  if (lowercase) {
    schema = schema.matches(/[a-z]/, errorMessage);
  }

  if (uppercase) {
    schema = schema.matches(/[A-Z]/, errorMessage);
  }

  if (number) {
    schema = schema.matches(/[0-9]/, errorMessage);
  }

  if (special) {
    schema = schema.matches(/[^a-zA-Z0-9]/, errorMessage);
  }

  return schema;
};

export const FormPasswordInput = (props: FormPasswordInputProps) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <FormInput
      containerClassName="form-input-container password-container"
      type={showPassword ? 'text' : 'password'}
      icon={props.icon}
      renderBefore={
        <span className="show-hide" onClick={() => setShowPassword(!showPassword)}>
          <Icon testId="password-toggle" icon={BsEye} size={IconSize.Medium} />
          {showPassword ? t('hide') : t('show')}
        </span>
      }
      {...props}
    />
  );
};
