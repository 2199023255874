import { useApolloClient } from '@apollo/client';
import { UserFragment, RolesDto, useLogoutMutation } from '../graphql/_generated';
import { cleanGqlObject } from './gql';
import {
  ADMIN_CANDIDATES_PATH,
  ENROL_PATH,
  ADVISOR_CANDIDATES_PATH,
  CANDIDATE_DASHBOARD_PATH,
} from '../constants/routes';

export const userSignupLoginRedirectLocation = (user: UserFragment) => {
  if (isAdmin(user)) {
    return ADMIN_CANDIDATES_PATH;
  }

  if (isAdvisor(user)) {
    return ADVISOR_CANDIDATES_PATH;
  }

  if (isCandidate(user)) {
    if (!user.roles.enrolled) {
      return ENROL_PATH;
    }

    return CANDIDATE_DASHBOARD_PATH;
  }

  return '/';
};

export const isCandidate = (user: UserFragment) => {
  const candidateRoles: Array<keyof RolesDto> = ['prospect', 'enrolled', 'waitlist'];
  return activeRoles(user).some((role) => candidateRoles.includes(role));
};

export const isAdmin = (user: UserFragment) => user.roles.admin;

export const isAdvisor = (user: UserFragment) => user.roles.advisor;

type RoleKey = keyof Omit<RolesDto, '__typename'>;
export const activeRoles = (user: {
  roles: Partial<UserFragment['roles']>;
}): Array<keyof Omit<UserFragment['roles'], '__typename'>> => {
  return Object.entries(cleanGqlObject(user.roles))
    .filter(([, active]) => active)
    .map(([role]) => role as RoleKey);
};

export const useLogout = () => {
  const client = useApolloClient();
  const [logout] = useLogoutMutation({
    errorPolicy: 'ignore',
  });

  const onClickLogout = async (e?: MouseEvent) => {
    e?.preventDefault();
    await logout()
      .catch()
      .finally(() => {
        client.stop();
        client.clearStore();
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = '/';
      });
  };

  return {
    onClickLogout,
  };
};
