import React from 'react';
import CookieConsentBase from 'react-cookie-consent';
import { useTranslation } from 'next-i18next';

export type CookieConsentProps = {
  termsLink: string;
  privacyLink: string;
};

export const CookieConsent = ({ termsLink, privacyLink }: CookieConsentProps) => {
  const { t } = useTranslation();
  return (
    <CookieConsentBase
      location="bottom"
      buttonText={t('okGotIt')}
      cookieName="aliro_consent"
      buttonStyle={{
        height: 'auto',
        borderRadius: '40px',
        padding: '0.5rem 1.5rem',
        font: 'Roboto, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1.2rem',
        color: '#000',
        border: '#9BD816 1px solid',
        backgroundColor: '#9BD816',
      }}
      buttonClasses="button button-primary"
    >
      <div className="cookie-consent">
        {t('cookieConsent')}
        &nbsp;
        <a href={privacyLink}>{t('privacyPolicy')}</a>
        &nbsp;
        {t('and')}
        &nbsp;
        <a href={termsLink}>{t('termsOfService')}</a>.
      </div>
    </CookieConsentBase>
  );
};
