import React from 'react';
import { useTranslation } from 'next-i18next';
import dayjs from 'dayjs';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <span className="copyright">
              <span>{t('copyright')} &copy; </span>
              <span>{dayjs().format('YYYY')}</span>
              <span>. {t('rightsReserved')}</span>
            </span>
            <span className="terms-privacy">
              <a href={`mailto:${t('contactUs.email')}?subject=${t('contactUs.subject')}`}>{t('contactUs.label')}</a>
              <a href="/faq">{t('faqs')}</a>
              <a href="/terms-of-use">{t('termsOfService')}</a>
              <a href="/privacy-policy">{t('privacyPolicy')}</a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};
