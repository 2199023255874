import React, { MouseEventHandler, useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { DatagridPagerProps, PaginationState } from './types';
import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import { Icon, IconSize } from '../icon/icon';
import { FaChevronLeft, FaChevronRight, FaChevronDown } from 'react-icons/fa';
import { DropdownMenuItem } from '../dropdown-menu/dropdown-menu-item';
import { DropdownMenu } from '../dropdown-menu/dropdown-menu';

const pageSizeOptions = [10, 25, 50, 100];

export const DatagridPager = ({ onPaginationChange, paginationState, totalRecords }: DatagridPagerProps) => {
  const { t } = useTranslation();
  const pages = useMemo(() => Math.ceil(totalRecords / paginationState.limit), [totalRecords, paginationState]);
  const currentPage = useMemo(
    () => Math.ceil(paginationState.offset / paginationState.limit) + 1,
    [paginationState?.offset, paginationState?.limit],
  );
  const [pageSizeLinkOpen, setPageSizeLinkOpen] = React.useState(false);

  const setPagination = useCallback(
    (newState: PaginationState) => {
      if (isEqual(newState, paginationState)) return;

      onPaginationChange(newState);
    },
    [paginationState, onPaginationChange],
  );

  const onClickPage = useCallback(
    (newPage: number): MouseEventHandler =>
      (evt) => {
        evt.preventDefault();
        setPagination({ offset: (newPage - 1) * paginationState.limit, limit: paginationState.limit });
      },
    [paginationState.limit, setPagination],
  );

  const nextPageNumber: number | undefined = currentPage < pages ? currentPage + 1 : undefined;
  const prevPageNumber: number | undefined = currentPage > 1 ? currentPage - 1 : undefined;

  if (totalRecords === 0) {
    return null;
  }

  return (
    <nav className="pagination u-display-flex u-justify-content-space-between">
      <DropdownMenu
        open={pageSizeLinkOpen}
        onOpenChange={(open: boolean) => setPageSizeLinkOpen(open)}
        trigger={
          <div
            role="button"
            data-testid="results-per-page"
            className="u-display-flex u-gap-2 u-align-items-center pointer"
          >
            {t('resultsPerPage').replace('{{value}}', String(paginationState.limit))}
            <FaChevronDown style={{ height: '1.2rem', fill: '#013a81' }} />
          </div>
        }
      >
        {pageSizeOptions.map((amount) => (
          <DropdownMenuItem
            role="button"
            onClick={() => setPagination({ offset: 0, limit: amount })}
            key={`results_per_page_${amount}`}
            data-value={amount}
          >
            {t('resultsPerPage').replace('{{value}}', String(amount))}
          </DropdownMenuItem>
        ))}
      </DropdownMenu>

      <div>
        <ul>
          <li
            className={`page-arrow prev ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={currentPage > 1 ? onClickPage(currentPage - 1) : undefined}
          >
            <Icon icon={FaChevronLeft} />
          </li>

          {[prevPageNumber, currentPage, nextPageNumber].map((page) => {
            if (!page) return null;

            return (
              <li
                key={`pageControl_${page}`}
                className={classnames({ 'page-number': true, 'selected-page': page === currentPage })}
                onClick={onClickPage(page)}
              >
                <p className="page">{page}</p>
              </li>
            );
          })}

          <li
            className={`page-arrow next ${pages === currentPage ? 'disabled' : ''}`}
            onClick={onClickPage(currentPage + 1)}
          >
            <Icon icon={FaChevronRight} size={IconSize.XSmall} />
          </li>
        </ul>

        <span className="page-count">
          <span>{t('page')} </span>
          {currentPage}
          <span> {t('of')} </span>
          {pages}
        </span>
      </div>
    </nav>
  );
};
